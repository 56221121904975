import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { Home, Basket, Photo, DefaultLayout, BasketLayout, PhotoLayout } from 'scenes';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';

const history = createBrowserHistory({});

const DefaultRoute = () => (
  <Router history={history}>
    <Switch>
      <Route path="/" component={wrapperHome} exact />
      <Route path="/basket" component={wrapperBasket} exact />
      <Route path="/photo" component={wrapperPhoto} exact />
    </Switch>
  </Router>
);

export default DefaultRoute;

// eslint-disable-next-line react/no-multi-comp
const wrapperHome = ({ match, ...rest }) => (
  <DefaultLayout {...rest}>
    <Switch>
      <Route path={`${match.url}`} component={Home} />
    </Switch>
  </DefaultLayout>
);

wrapperHome.propTypes = {
  match: PropTypes.object,
};

// eslint-disable-next-line react/no-multi-comp
const wrapperBasket = ({ match }) => (
  <BasketLayout>
    <Switch>
      <Route path={`${match.url}`} component={Basket} />
    </Switch>
  </BasketLayout>
);

wrapperBasket.propTypes = {
  match: PropTypes.object,
};

// eslint-disable-next-line react/no-multi-comp
const wrapperPhoto = ({ match }) => (
  <PhotoLayout>
    <Switch>
      <Route path={`${match.url}`} component={Photo} />
    </Switch>
  </PhotoLayout>
);

wrapperPhoto.propTypes = {
  match: PropTypes.object,
};
