import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class Home extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      deviceIdNumber: null,
    };
  }

  defaultDeviceIdChooser (filteredDevices, videoDevices, facingMode) {
    if (filteredDevices.length > 0) {
      return filteredDevices[0].deviceId;
    }
    if (videoDevices.length === 1 || facingMode === 'user') {
      return videoDevices[0].deviceId;
    }
    return videoDevices[1].deviceId;
  }

  getFacingModePattern = facingMode => (facingMode === 'environment' ? /rear|back|environment/gi : /front|user|face/gi);

  getDeviceId = (facingMode, chooseDeviceId = this.defaultDeviceIdChooser) => {
    // Get manual deviceId from available devices.
    return new Promise((resolve, reject) => {
      let enumerateDevices;
      try {
        enumerateDevices = navigator.mediaDevices.enumerateDevices();
      } catch (err) {
        reject(err);
      }
      enumerateDevices.then(devices => {
        // Filter out non-videoinputs
        const videoDevices = devices.filter(device => device.kind === 'videoinput');

        if (videoDevices.length < 1) {
          reject('error');
          return;
        }

        const pattern = this.getFacingModePattern(facingMode);

        // Filter out video devices without the pattern
        const filteredDevices = videoDevices.filter(({ label }) => pattern.test(label));

        resolve(chooseDeviceId(filteredDevices, videoDevices, facingMode));
      });
    });
  };

  initCamera = view => {
    this.getDeviceId(view)
      .then(selectedDeviceId => {
        this.setState({
          deviceIdNumber: selectedDeviceId,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  componentDidMount () {
    const { cameraView } = this.props;
    this.initCamera(cameraView);
    this.setState({ loaded: true });
  }

  componentDidUpdate () {
    const { cameraView } = this.props;
    this.initCamera(cameraView);
  }

  getDeviceIdNumber = () => {
    const { deviceIdNumber } = this.state;
    return deviceIdNumber ? 'deviceId:' + deviceIdNumber : '';
  };
  render () {
    const { deviceIdNumber } = this.state;

    return (
      <div key={deviceIdNumber || 1} className="scene">
        <a-scene
          embedded
          arjs={`sourceType: webcam; debugUIEnabled: false; trackingMethod: best; ${this.getDeviceIdNumber()};`}
          vr-mode-ui="enabled: false"
        >
          <a-marker-camera preset="custom" type="pattern" url="../assets/images/pattern-marker.patt"></a-marker-camera>

          <a-entity scale="1.5 1.5 1.5" position="0.1 0.1 0.1" rotation="-90 0 0">
            <a-obj-model mtl="../assets/images/turtle.mtl" src="../assets/images/turtle.obj"></a-obj-model>
          </a-entity>
        </a-scene>

        <div id="wrapper"></div>
        <canvas id="screen-canvas"></canvas>
      </div>
    );
  }
}

Home.defaultProps = {};

Home.propTypes = {
  cameraView: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    cameraView: state.camera,
  };
};

export default connect(mapStateToProps)(Home);
