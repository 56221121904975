import React from 'react';

export class IconDownload extends React.PureComponent {
  render () {
    return (
      <svg
        className="icon icon-download"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 34 34"
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-323.000000, -761.000000)">
            <g transform="translate(325.000000, 762.000000)">
              <path
                d="M23.1821823,17.1470261 L16.4142136,23.9149948 C15.633165,24.6960434 14.366835,24.6960434 13.5857864,23.9149948 L6.8178177,17.1470261 C6.36015773,16.6893661 6.36015773,15.9473527 6.8178177,15.4896927 L6.8178177,15.4896927 C7.27547767,15.0320327 8.01749108,15.0320327 8.47515105,15.4896927 L13.828125,20.8426666 L13.828125,1.171875 C13.828125,0.524666309 14.3527913,-5.67245286e-14 15,-5.68434189e-14 L15,-5.68434189e-14 C15.6472087,-5.69623092e-14 16.171875,0.524666309 16.171875,1.171875 L16.171875,20.8426666 L21.5248489,15.4896927 C21.9825089,15.0320327 22.7245223,15.0320327 23.1821823,15.4896927 L23.1821823,15.4896927 C23.6398423,15.9473527 23.6398423,16.6893661 23.1821823,17.1470261 Z M28.828125,27.65625 L1.171875,27.65625 C0.524666309,27.65625 -7.92602052e-17,28.1809163 0,28.828125 L0,28.828125 C7.92602052e-17,29.4753337 0.524666309,30 1.171875,30 L28.828125,30 C29.4753337,30 30,29.4753337 30,28.828125 L30,28.828125 C30,28.1809163 29.4753337,27.65625 28.828125,27.65625 Z"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

IconDownload.defaultProps = {};

IconDownload.propTypes = {};
