import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

export class Basket extends React.PureComponent {
  render () {
    const { product } = this.props;
    return (
      <div className="section section-basket">
        <div className="container">
          <div className="section-basket__inner">
            <div className="section-head">
              <strong className="section-title">ORDER</strong>
            </div>
            <div className="section-basket__product">
              <div className="section-basket__preview">
                <img src={product.src} />
              </div>
              <strong className="section-basket__title">{product.title}</strong>
            </div>
            <div className="section-basket__form">
              <div className="form-default">
                <div className="form-default__line">
                  <div className="form-group">
                    <input id="fname" className="form-control" type="text" placeholder="placeholder" />
                    <label className="form-label" htmlFor="fname">
                      Introduce yourself
                    </label>
                  </div>
                </div>
                <div className="form-default__line">
                  <div className="form-group">
                    <InputMask
                      mask="+999 (99) 99 99 999"
                      className="form-control"
                      id="fphone"
                      placeholder="placeholder"
                    ></InputMask>
                    <label className="form-label" htmlFor="fphone">
                      Phone
                    </label>
                  </div>
                </div>
                <div className="form-default__line">
                  <div className="form-group">
                    <input id="femail" className="form-control" type="email" placeholder="placeholder" />
                    <label className="form-label" htmlFor="femail">
                      Email
                    </label>
                  </div>
                </div>
                <div className="form-default__line">
                  <div className="form-group">
                    <textarea id="fmessage" className="form-control" placeholder="placeholder"></textarea>
                    <label className="form-label" htmlFor="fmessage">
                      Message
                    </label>
                  </div>
                </div>
                <div className="form-default__footer">
                  <button type="submit" className="btn btn-default">
                    ORDER
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Basket.defaultProps = {};

Basket.propTypes = {
  product: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    product: state.basket,
  };
};

export default connect(mapStateToProps)(Basket);
