import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconDownload } from 'icons';
// import PropTypes from 'prop-types';

export class Photo extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {};
  }

  render () {
    const { photoSrc } = this.props;

    return (
      <div className="layout-photo">
        <div className="layout-photo__image" style={{ backgroundImage: `url(${photoSrc})` }}></div>
        <div className="layout-photo__footer">
          <div className="container">
            <div className="layout-photo__inner">
              <a download="fancyclover.png" href={photoSrc} className="btn btn-download">
                <IconDownload></IconDownload>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Photo.defaultProps = {};

Photo.propTypes = {
  photoSrc: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    photoSrc: state.photo,
  };
};

export default connect(mapStateToProps)(Photo);
