import React from 'react';
import PropTypes from 'prop-types';
import { LogoFancyClover, IconBack } from 'icons';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({});

export class Header extends React.PureComponent {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className="layout-header">
        <div className="container">
          <div className="layout-header__inner">
            {window.location.pathname !== '/' ? (
              <div className="layout-header__back">
                <button className="btn btn-back" onClick={() => history.goBack()}>
                  <IconBack></IconBack>
                </button>
              </div>
            ) : null}

            <a href="https://fancyclover.com/" target="_blank" rel="noopener noreferrer">
              <LogoFancyClover />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

Header.defaultProps = {};

Header.propTypes = {
  onClick: PropTypes.func,
};
