import React from 'react';
import PropTypes from 'prop-types';
import { Header } from 'components';

export const PhotoLayout = ({ children }) => (
  <div className="layout layout-photo">
    <Header />
    <div className="layout-body">{children}</div>
  </div>
);

PhotoLayout.defaultProps = {};

PhotoLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
