import React from 'react';

export class IconBack extends React.PureComponent {
  render () {
    return (
      <svg
        className="icon icon-back"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 27 21"
      >
        <g transform="translate(-22.000000, -26.000000)">
          <g transform="translate(20.000000, 20.000000)">
            <g transform="translate(4.000000, 6.454545)">
              <path
                d="M7.50271871,0.801134054 C7.82696322,0.460228043 8.33742925,0.460228043 8.66167377,0.801134054 C8.97496408,1.13052297 8.97496408,1.67873669 8.66167377,2.0073578 L2.78510704,8.18589536 L22.1769739,8.18589536 C22.6290175,8.18589536 23,8.56365607 23,9.03892819 C23,9.51420031 22.6290175,9.90424593 22.1769739,9.90424593 L2.78510704,9.90424593 L8.66167377,16.0712664 C8.97496408,16.4121724 8.97496408,16.9611539 8.66167377,17.289775 C8.33742925,17.630681 7.82696322,17.630681 7.50271871,17.289775 L0.234967733,9.64856642 C-0.0783225776,9.3191775 -0.0783225776,8.77096378 0.234967733,8.44234267 L7.50271871,0.801134054 Z"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
