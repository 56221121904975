import React from 'react';

export class IconBasket extends React.PureComponent {
  render () {
    return (
      <svg
        className="icon icon-basket"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 27 34"
      >
        <g transform="translate(-69.000000, -761.000000)">
          <g transform="translate(67.000000, 762.000000)">
            <g transform="translate(4.000000, 0.000000)">
              <path
                d="M15.4902835,8.0985 C15.7706276,8.0985 16,7.8315 16,7.4985 C16,7.1685 15.7706276,6.9015 15.4902835,6.9015 L8.50971647,6.9015 C8.22937241,6.9015 8,7.1685 8,7.4985 C8,7.8315 8.22937241,8.0985 8.50971647,8.0985 L15.4902835,8.0985 Z"
                fillRule="nonzero"
              ></path>
              <path
                d="M22.453487,7 L18.7399311,7 C18.4393489,7 18.1961506,7.27152142 18.1961506,7.6101605 C18.1961506,7.94574877 18.4393489,8.21727019 18.7399311,8.21727019 L21.906974,8.21727019 L21.906974,28.7827298 L1.09029345,28.7827298 L1.09029345,8.21727019 L4.07698705,8.21727019 C4.37756921,8.21727019 4.62350006,7.94574877 4.62350006,7.6071097 C4.62350006,7.27152142 4.37756921,7 4.07698705,7 L0.546513009,7 C0.243198289,7 0,7.27152142 0,7.6071097 L0,29.3928903 C0,29.7284786 0.243198289,30 0.546513009,30 L22.453487,30 C22.7540691,30 23,29.7284786 23,29.3928903 L23,7.6071097 C23,7.27152142 22.7540691,7 22.453487,7"
                fillRule="nonzero"
              ></path>
              <path
                d="M17.4538006,10 C17.7542103,10 18,9.73095526 18,9.39540508 L18,5 C17.997269,2.24304716 15.3099681,0 11.991807,0 C8.68730086,0 6,2.24304716 6,5 L6,9.39842805 C6,9.73095526 6.24305872,10 6.54346837,10 C6.84387802,10 7.08966773,9.73095526 7.08966773,9.39842805 L7.08966773,5 C7.08966773,2.90810157 9.28812016,1.20616687 12.005462,1.20616687 C14.7091488,1.20616687 16.9103323,2.90810157 16.9103323,5 L16.9103323,9.39842805 C16.9076013,9.73095526 17.153391,10 17.4538006,10"
                fillRule="nonzero"
              ></path>
              <path
                d="M5,11.0305 C5,11.8795 5.44790652,12.5695 6.00097371,12.5695 C6.55209348,12.5695 7,11.8795 7,11.0305 C7,10.7005 6.82667965,10.4305 6.61051607,10.4305 C6.3962999,10.4305 6.22297955,10.7005 6.22297955,11.0305 C6.22297955,11.2195 6.12366115,11.3725 5.99902629,11.3725 C5.87633885,11.3725 5.77702045,11.2195 5.77702045,11.0305 C5.77702045,10.7005 5.6037001,10.4305 5.38753651,10.4305 C5.17332035,10.4305 5,10.7005 5,11.0305"
                fillRule="nonzero"
              ></path>
              <path
                d="M17,12.5695 C17.5505837,12.5695 18,11.8795 18,11.0305 C18,10.7005 17.8249027,10.4305 17.6108949,10.4305 C17.3968872,10.4305 17.2237354,10.7005 17.2237354,11.0305 C17.2237354,11.2195 17.1225681,11.3725 17,11.3725 C16.8774319,11.3725 16.7762646,11.2195 16.7762646,11.0305 C16.7762646,10.7005 16.6031128,10.4305 16.3891051,10.4305 C16.1750973,10.4305 16,10.7005 16,11.0305 C16,11.8795 16.4494163,12.5695 17,12.5695"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
