import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IconCamera, IconBasket } from 'icons';
import _ from 'lodash';
import { cameraAction, addProduct, photoAction } from 'redux/actions';
import Carousel from 'nuka-carousel';
import { Link } from 'react-router-dom';

const items = {
  0: {
    src: '../assets/images/01.jpg',
    price: 25,
    title: 'Rabbit Pearl I',
  },
  1: {
    src: '../assets/images/02.jpg',
    price: 55,
    title: 'Rabbit Fur II',
  },
  2: {
    src: '../assets/images/03.jpg',
    price: 45,
    title: 'Lizard Deco I',
  },
  3: {
    src: '../assets/images/04.jpg',
    price: 15,
    title: 'Turtle mini',
  },
  4: {
    src: '../assets/images/05.jpg',
    price: 65,
    title: 'Rabbit Flower I',
  },
  5: {
    src: '../assets/images/06.jpg',
    price: 85,
    title: 'Turtle Flower IV',
  },
  6: {
    src: '../assets/images/03.jpg',
    price: 5,
    title: 'Turtles',
  },
  7: {
    src: '../assets/images/04.jpg',
    price: 215,
    title: 'Flower IV',
  },
  8: {
    src: '../assets/images/05.jpg',
    price: 165,
    title: 'Croco Сouple IX',
  },
  9: {
    src: '../assets/images/06.jpg',
    price: 2215,
    title: 'Broosh Rock it, baby!',
  },
  10: {
    src: '../assets/images/02.jpg',
    price: 365,
    title: 'Broosh Love sucks',
  },
  11: {
    src: '../assets/images/03.jpg',
    price: 565,
    title: 'Broosh My alter ego',
  },
};

const arr = _.values(items);

export class Footer extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      price: items[0].price,
      // centerElement: 0,
      slideIndex: 0,
      slidesToShow: 5,
      cellAlign: 'center',
      transitionMode: 'scroll3d',
      zoomScale: 1,
      // photoSrc: null,
      itemNumber: 0,
    };

    this.onGoTo = this.onGoTo.bind(this);
  }

  changeSlide (i) {
    this.setState({ price: i });
  }

  onGoTo = el => {
    this._carousel.setState({ currentSlide: el });
    this.setState({ price: items[el].price, itemNumber: el });
  };

  getPhoto = () => {
    const video = document.querySelector('video');
    const canvas = (window.canvas = document.getElementById('screen-canvas'));
    const arCanvas = document.getElementsByClassName('a-grab-cursor')[0];
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const { history } = this.props;

    // Takes a snapshot of the video
    const snap = () => {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.getContext('2d').drawImage(arCanvas, 0, 0, canvas.width, canvas.height);

      const dataURL = canvas.toDataURL();

      this.setState({ photoSrc: dataURL });

      const { dispatch } = this.props;
      dispatch(photoAction(dataURL));
    };

    snap();

    history.push('/photo/');

    return false;
  };

  changeCamera = camera => {
    const { dispatch } = this.props;
    dispatch(cameraAction(camera));
  };

  addBasket = product => {
    const { dispatch } = this.props;
    dispatch(addProduct(product));
  };

  render () {
    const { price, cellAlign, slidesToShow, slideIndex, transitionMode, zoomScale, itemNumber } = this.state;
    const { cameraView } = this.props;

    console.log(cameraView, 'camera');

    return (
      <>
        <div className="layout-footer">
          <div className="module-gallery">
            <Carousel
              cellAlign={cellAlign}
              slidesToShow={slidesToShow}
              slideIndex={slideIndex}
              transitionMode={transitionMode}
              zoomScale={zoomScale}
              ref={c => {
                this._carousel = c;
              }}
            >
              {arr.map((el, index) => (
                <div
                  className="module-gallery__item"
                  key={index}
                  data-number={index}
                  onClick={() => this.onGoTo(index)}
                >
                  <div className="module-gallery__preview">
                    <img src={el.src} />
                  </div>
                </div>
              ))}
            </Carousel>
            <div className="module-gallery__circle" onClick={() => this.getPhoto()}></div>
            {/* <div className="module-gallery__circle" onClick={() => this.getPhoto()}></div> */}
          </div>
          <div className="container">
            <div className="layout-footer__inner">
              <div className="module-price">
                <div className="module-price__currency">$</div>
                <div className="module-price__amount">{price}</div>
                <div className="module-price__icon">
                  <Link to={'/basket/'} onClick={() => this.addBasket(items[itemNumber])}>
                    <IconBasket />
                  </Link>
                </div>
              </div>
              <div className="module-rotate">
                <div className="module-rotate__control">
                  <IconCamera onClick={() => this.changeCamera(cameraView === 'user' ? 'environment' : 'user')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {photoSrc !== null ? <Photo photoSrc={photoSrc}></Photo> : null} */}
      </>
    );
  }
}

Footer.defaultProps = {};

Footer.propTypes = {
  onClick: PropTypes.func,
  dispatch: PropTypes.func,
  cameraView: PropTypes.string,
  history: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    cameraView: state.camera,
  };
};

export default connect(mapStateToProps)(Footer);
