import { combineReducers } from 'redux';
import { authReducer } from './auth/auth.reducer';
import { cameraReducer } from './camera/camera.reducer';
import { basketReducer } from './basket/basket.reducer';
import { photoReducer } from './photo/photo.reducer';

export default combineReducers({
  token: authReducer,
  camera: cameraReducer,
  basket: basketReducer,
  photo: photoReducer,
});
