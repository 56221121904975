import React from 'react';
import PropTypes from 'prop-types';
import { Header, Footer } from 'components';

export const DefaultLayout = ({ children, ...rest }) => (
  <>
    <div className="layout-home">
      <Header />
      <Footer {...rest} />
    </div>
    {children}
  </>
);

DefaultLayout.defaultProps = {};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
